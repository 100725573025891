import { useSearchParams } from 'react-router';
import { getCheckoutUrl } from './checkoutService';
import { FormattedMessage } from 'react-intl';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import { HomescreenFreeTrialSection } from './freeTrial/HomescreenFreeTrialSection';

function RecommendationsBody() {
    return (
        <ApplicationLayout className='flex-1-1 overflow-y-auto'>
            <div className='padding-20'>
                <div className='panel panel-default padding-5pct margin-bottom-0'>
                    <div className='display-flex-sm gap-25'>
                        <div className='flex-1-1-0 line-height-125rel'>
                            <h3>
                                <FormattedMessage id={'intl-msg:recommendations.vehiclesAdded.headline'} />
                            </h3>
                            <p>
                                <FormattedMessage id={'intl-msg:recommendations.vehiclesAdded.text'} />
                            </p>
                            <h4>
                                <FormattedMessage id={'intl-msg:recommendations.geo.headline'} />
                            </h4>
                            <p>
                                <FormattedMessage
                                    id={'intl-msg:recommendations.geo.description'}
                                    values={{
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        link: (chunks: Array<string>) => (
                                            <strong>
                                                <a
                                                    href='https://rio.cloud/en/marketplace/produkt/geo'
                                                    target='_blank'
                                                    rel='noreferrer'
                                                >
                                                    {chunks}
                                                </a>
                                            </strong>
                                        ),
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        bold: (chunks: Array<string>) => <strong>{chunks}</strong>,
                                    }}
                                />
                            </p>
                        </div>
                        <div className='width-300 width-200-md margin-x-auto'>
                            <img
                                className='img-responsive'
                                alt='geo'
                                src='https://rio.cloud/fileadmin/Marketplace/RIO/Products/Geo/General/geo_routeplanning_desktop_right.png'
                            />
                        </div>
                    </div>
                </div>
                <HomescreenFreeTrialSection></HomescreenFreeTrialSection>
            </div>
        </ApplicationLayout>
    );
}

function RecommendationsFooter({ targetUrl }: { targetUrl: string }) {
    return (
        <div className='display-flex justify-content-between padding-20 bg-white border border-top-only border-color-light rounded-bottom hidden-empty'>
            <button className='btn btn-primary' type='button' onClick={() => history.back()}>
                <FormattedMessage id={'intl-msg:recommendations.back'} />
            </button>
            <a href={targetUrl}>
                <button className='btn btn-primary' type='button'>
                    <FormattedMessage id={'intl-msg:recommendations.geo.continue'} />
                </button>
            </a>
        </div>
    );
}

const Recommendations = () => {
    const [searchParams] = useSearchParams();
    const deviceType = searchParams.get('deviceType');
    const resourceIds = searchParams.get('resourceIds')?.split(',') || [];
    const targetUrl = getCheckoutUrl(deviceType, resourceIds);

    return (
        <div id='recommendations' className='display-flex flex-column height-100vh'>
            <RecommendationsBody />
            <RecommendationsFooter targetUrl={targetUrl} />
        </div>
    );
};

export default Recommendations;
